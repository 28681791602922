/**
 * Application entry point.
 *
 * This file includes `import`'s for all the components/directives used
 * by the HTML page and the controller to provide the configuration.
 */

import './sass/desktop.scss';
import 'gmf/controllers/desktop.scss';
import './sass/vars_desktop.scss';

import angular from 'angular';
import { isEventUsinCtrlKey } from 'ngeo/utils.js';
import gmfControllersAbstractDesktopController, {
  AbstractDesktopController,
} from 'gmf/controllers/AbstractDesktopController.js';
import morgesBase from '../morgesmodule.js';
import ngeoStreetviewModule from 'ngeo/streetview/module.js';


if (!window.requestAnimationFrame) {
  alert(
    'Your browser is not supported, please update it or use another one. You will be redirected.\n\n' +
      "Votre navigateur n'est pas supporté, veuillez le mettre à jour ou en utiliser un autre. " +
      'Vous allez être redirigé.\n\n' +
      'Ihr Browser wird nicht unterstützt, bitte aktualisieren Sie ihn oder verwenden Sie einen anderen. ' +
      'Sie werden weitergeleitet.'
  );
  window.location.href = 'https://geomapfish.org/';
}

/**
 * @private
 */
class Controller extends AbstractDesktopController {
  /**
   * @param {JQueryEventObject} event keydown event.
   */
  constructor($scope, $injector) {
    super($scope, $injector);
	
	
    // Share Iframe
    this.setIframeLink = () => {
      $scope.loadIframe = true;
      const path = this.ngeoLocation.getUriString();
      const urlThemeSplit = path.split('/');
      const paramUrl = urlThemeSplit[urlThemeSplit.length - 1];
      this.urliframe = 'https://map.cjl.ch/iframe_api' + paramUrl;
    };

    $scope.iframeSizes = [
      {
        label: 'Petite',
        width: 400,
        height: 350,
      },
      {
        label: 'Moyenne',
        width: 600,
        height: 450,
      },
      {
        label: 'Grande',
        width: 800,
        height: 550,
      },
    ];

    $scope.iframeSize = $scope.iframeSizes[0];

    this.copyToClipboard = () => {
      const copyText = document.getElementById('urlIframe');
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand('copy');
      document.getElementById('copyButton').innerHTML = 'Lien copié';
      setTimeout(function () {
        document.getElementById('copyButton').innerHTML = 'Copier le lien';
      }, 2000);
    };

	
	
	
	
    /**
     * Call ASITVD URL with the current bbox
     */

    /**
     * const asitvdUrl = $injector.has('asitvdBaseUrl') ? $injector.get('asitvdBaseUrl') : 'asitvdBaseUrl_not_defined';
     */
    this.openAsitWithBbox = () => {
      //Get the current bbox
      const view = this.map.getView();
      const extent = view.calculateExtent(this.map.getSize());
      //Round the values
      let x = 0;
      const len = extent.length;
      while (x < len) {
        extent[x] = extent[x].toFixed(0);
        x++;
      }

      /**
       * Open URL
       */
      window.open(
        'https://viageo.ch/api/shop-select/bbox/' +
          extent.join('/') +
          '?provider=a3da076d-bf51-4fc1-a0be-a65a3c704759,\
b2c7a276-f726-41e4-abbc-b721836b0645,\
b046d19c-496a-4533-a68d-e5a988aaa499,\
59114534-992e-4522-b0ce-814d4a41c46b'
      );
    };
  }

  onKeydown(event) {
    if (event && isEventUsinCtrlKey(event) && event.key === 'p') {
      this.printPanelActive = true;
      event.preventDefault();
    }
  }
}

/**
 * @hidden
 */
const morgesModule = angular.module('Appdesktop', [
  morgesBase.name,
  gmfControllersAbstractDesktopController.name,
  ngeoStreetviewModule.name,
]);

morgesModule.value('gmfContextualdatacontentTemplateUrl', 'gmf/contextualdata');
morgesModule.run(
  /* @ngInject */ ($templateCache) => {
    // @ts-ignore: webpack
    $templateCache.put('gmf/contextualdata', require('./contextualdata.html'));
  }
);

morgesModule.controller('DesktopController', Controller);

/**exports.module.value('asitvdBaseUrl', 'https://viageo.ch/api/shop-select/bbox/');*/

morgesModule.value('ngeoQueryOptions', {
  limit: 3000,
});

export default morgesModule;
